import React from 'react'
import assadkoshul from '../../images/Assad-Koshul--.png'
import ilyasahshabazz from '../../images/ilyasahshabazz-circle.png'
import malinkshaw from '../../images/malik-shaw.jpg'
import zaidimg from '../../images/Imam-Zaid-Shakir.png'
import davidimg from '../../images/david.png'
import aishaimg from '../../images/aisha.png'
import sign from '../../images/signeture.png'
import AssadModal from '../Team/AssadModal'
import Ilyasahshabazzbio from './IlyasahShabazzBio'
import MalikModal from '../Team/MalikModal'
import AishaModal from '../Team/MalikModal'
import DavidModal from '../Team/DavidModal'
import ZaidModal from '../Team/ZaidModal'


import '../../css/custom.css';


const Team = (props) => {

    return(
        <section id="team" className=" tp-about-section ">
            <div className="container">
                <div className="col-12 text-center">
                    <div className='section-title section-title2'>
                            <h2  className='bg-repeat'> Team</h2>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-4  text-center'>
                        <img src={assadkoshul} className='col-10 y img-fluid rounded-circle'/>
                        <h3 className='text-white mt-4'>Assad Koshul</h3>
                        <p className='text-white '>FOUNDER</p>
                        <AssadModal buttonClass={'border-secondary border-top btn mt-2 text-light'}/>
                    </div>
                    {/* <div className='col-12 col-md-4 mt-5 mt-md-0  text-center'>
                        <img src={ilyasahshabazz} className='col-10  img-fluid rounded-circle'/>
                        <h3 className='text-white mt-4'>Ilyasah Shabazz</h3>
                        <p className='text-white '>Founder</p>
                        <Ilyasahshabazzbio buttonClass={'border-secondary border-top btn mt-2 text-light'}/>
                    </div> */}
                    
                    </div>
<br></br>
<br></br>
<br></br>
<br></br>

                    <div className="col-12 text-center mt-5">
                    <div className='section-title section-title2  mt-5'>
                            <h2  className='bg-repeat  mt-5'>Board of Advisors</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-4  text-center'>
                        <img src={zaidimg} className='col-10 img-fluid rounded-circle'/>
                        <h3 className='text-white mt-4'>Imam Zaid Shakir </h3>
                        <ZaidModal buttonClass={'border-secondary border-top btn mt-2 text-light'}/>
                    </div>
                    <div className='col-12 col-md-4  text-center'>
                        <img src={aishaimg} className='col-10 img-fluid rounded-circle'/>
                        <h3 className='text-white mt-4'>Aisha Aidawaya </h3>
                        <AishaModal buttonClass={'border-secondary border-top btn mt-2 text-light'}/>
                    </div>
                    <div className='col-12 col-md-4  text-center'>
                        <img src={davidimg} className='col-10  img-fluid rounded-circle'/>
                        <h3 className='text-white mt-4'>Dr. David Gilliam </h3>
                        <DavidModal buttonClass={'border-secondary border-top btn mt-2 text-light'}/>
                    </div>
                    
                 </div>
            </div>
           
        </section>
    )
}

export default Team;