import React, { Component } from 'react';
import Logo from '../../images/logo1212.png';
import {Link} from 'react-router-dom'

class Footer extends Component {
    render() {
        return (
            <div className="footer-area text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-image">
                                <Link to="/home">
                                    <img src={Logo} alt=""/>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="footer-menu">
                                <ul className="d-flex " >
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/malcolmxvisionary"><i className="fa fa-facebook"></i></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/malcolmxvisionary/"><i className="fa fa-instagram"></i></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/malcolmxvision"><i className="fa fa-twitter"></i></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/malcolmxvisionary/"><i className="fa fa-linkedin"></i></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@malcolmxvisionary"><i className="fa fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="footer-sub">
                                <p><i className="fa fa-copyright"></i>Copyright <span> 2021 <a className='text-light' href="http://pwsolution.com/">PWS LLC</a>. All rights reserved</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;